<template >
  <div class="background">
    <div class="mx-auto background" v-if="agenda.Id">
      <v-card
        max-width="1200px"
        height="80vh"
        elevation="1"
        class="mx-auto my-12 pa-6 rounded-xl white"
      >
        <v-card-title class="d-flex justify-center"
          >Anulación de hora exitosa</v-card-title
        >
        <v-card-text>
          <v-row class="d-flex justify-center">
            <div class="d-flex justify-center">
              <v-avatar tile size="150">
                <v-img contain sizes="200" src="/cancel.svg"></v-img>
              </v-avatar>
            </div>
          </v-row>
          <v-row class="pa-0">
            <v-col class="d-flex flex-column align-center my-4">
              <div>
                Gracias por informar, puede pedir una hora cuando la requiera.
              </div>
              <div>Detalles de la hora anulada para {{ agenda.Mascota }}</div>
              <div>
                Fecha: {{ agenda.Fecha.substring(0, 10) }} a las
                {{ agenda.Hora }} con el profesional {{ agenda.Nombredoc }} en
                {{ agenda.Sede }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else absolute>
      <v-overlay absolute opacity="2" color="background">
        <v-progress-circular indeterminate :size="200" color="primary" class="">
          Cargando</v-progress-circular
        >
      </v-overlay>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      agenda: {
        Id: "",
      },
    };
  },
  methods: {
    ...mapActions(["getAnulacionAgendaByIdView"]),
    async loadConfirmacion() {
      if (this.$route.params.id) {
        this.agenda = await this.getAnulacionAgendaByIdView({
          id: this.$route.params.id,
        });
      }
    },
  },
  created() {
    this.loadConfirmacion();
  },
};
</script>
<style lang="">
</style>